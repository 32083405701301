import React, { useRef, useState } from 'react';
import { useSearchParams } from "react-router-dom";
//2024 tables
const periodsPerYear=24;//semi-monthly
const FitWh2024=[// https://www.irs.gov/pub/irs-prior/p15t--2024.pdf
    [ 0                    , 6000/periodsPerYear  , 0  ],// page 11
    [ 6000/periodsPerYear  , 17600/periodsPerYear , 10 ],
    [ 17600/periodsPerYear , 53150/periodsPerYear , 12 ],
    [ 53150/periodsPerYear , 106525/periodsPerYear, 22 ],
    [ 106525/periodsPerYear, 197950/periodsPerYear, 24 ],
    [ 197950/periodsPerYear, 249725/periodsPerYear, 32 ],
    [ 249725/periodsPerYear, 615350/periodsPerYear, 35 ],
    [ 615350/periodsPerYear, 0.1                  , 37 ]
]
const OkWh2024 = [// https://oklahoma.gov/content/dam/ok/en/tax/documents/resources/publications/businesses/withholding-tables/WHTables-2024.pdf
    [0                   , 6350/periodsPerYear , 0                   , 0   ],// page 8
    [6350/periodsPerYear , 7350/periodsPerYear , 0                   , 0.25],
    [7350/periodsPerYear , 8850/periodsPerYear , 2.5/periodsPerYear  , 0.75],
    [8850/periodsPerYear , 10100/periodsPerYear, 13.75/periodsPerYear, 1.75],
    [10100/periodsPerYear, 11250/periodsPerYear, 35.63/periodsPerYear, 2.75],
    [11250/periodsPerYear, 13550/periodsPerYear, 67.25/periodsPerYear, 3.75],
    [13550/periodsPerYear, 0.1                 , 153.5/periodsPerYear, 4.75]
]
function roundToCents(num){if (typeof num === "string")return num;else return Math.floor(num*100)/100;}
function TaxCalculator({rate}) {
    const searchParams = useSearchParams()[0];
    const setSearchParams = useSearchParams()[1];
    let sum=0;
    for(let i=0;i<31;i++){
        const thing = Number(searchParams.get(String(i+1)));
        if (thing!=null) sum+=thing;
    }
    const dphRef = useRef();
    const [dph, setDph] = useState(rate);
    const beforeTax = sum*dph;
    const Ok = (()=>{
        for(var i=0;i<OkWh2024.length-1;i++) {
            const tx = OkWh2024[i];
            if (beforeTax >= tx[0] && beforeTax < tx[1]) {
                const amount=tx[2]+((beforeTax-tx[0])*(tx[3]/100));
                return [...tx,amount];
            }
        }
        const tx = OkWh2024[OkWh2024.length-1];// the amount between 615350/periodsPerYear to infinity
        const amount=tx[2]+((beforeTax-tx[0])*(tx[3]/100));
        return [tx[0],"∞",tx[2],tx[3],amount];
    })();
    const Fit = (()=>{
        for(var i=0;i<FitWh2024.length-1;i++) {
            const tx = FitWh2024[i];
            if (beforeTax >= tx[0] && beforeTax < tx[1]) {
                const amount=((beforeTax-tx[0])*(tx[2]/100));
                return [...tx,amount];
            }
        }
        const tx = FitWh2024[FitWh2024.length-1];
        const amount=((beforeTax-tx[0])*(tx[2]/100));
        return [tx[0],"∞",tx[2],amount];// the amount between 13550/periodsPerYear to infinity
    })();
    const final = beforeTax-Ok[4]-Fit[3]-(beforeTax*0.0765);
    return (<div className="TaxCalculator">
        <div>{sum+" hours total"}</div>
        <div className="input">
            <label>$/hr:</label>
            <input ref={dphRef} type="number" onChange={()=>{
                setDph(Number(dphRef.current.value));
                let params = Object.fromEntries((()=>{var a=window.location.search.replace("?","").split("&").map((ent)=>ent.split("="));return a[0][0]!==""?a:[]})());
                params["rate"]=dphRef.current.value;
                console.log(dphRef.current.value);
                setSearchParams(params);
            }} value={dph}></input>
        </div>
        <div>{"(" + sum + " hours)*(" + dph + " $/hr) = $" + beforeTax + " before tax"}</div>
        <div>{"Ok between $" + roundToCents(Ok[0]) + " and $" + roundToCents(Ok[1]) + ":"}</div><div>{"$" + roundToCents(Ok[2]) + " + (" + Ok[3] + "% of ($" + beforeTax + " - $" + roundToCents(Ok[0]) + ")) = $" + Ok[4]}</div><br/>
        <div>{"Fit between $" + roundToCents(Fit[0]) + " and $" + roundToCents(Fit[1]) + ":"}</div><div>{"$(" + Fit[2] + "% of ($" + beforeTax + " - $" + roundToCents(Fit[0]) + ")) = $" + Fit[3]}</div><br/>
        <div>{"Med: 1.45% of $" + beforeTax + ": $" + Math.floor(beforeTax*0.0145*100)/100}</div>
        <div>{"Soc: 6.2% of $" + beforeTax + ": $" + Math.floor(beforeTax*0.062*100)/100}</div>
        <div>{"Final: $" + beforeTax + " - $" + Fit[3] + " - $" + Ok[4] + " - $" + beforeTax*0.0145 + " - $" + beforeTax*0.062 + ":"}</div>
        <div className="final">{"$" + roundToCents(final)}</div>
    </div>)
}
export default TaxCalculator;